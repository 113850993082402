html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

hr {
  border-color: #a3b1bf;
}



.modal input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #FFF !important;
  background: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #253746 !important;
  background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

/* remember to define focus styles! */

:focus {
  outline: 0 !important;
}

/* remember to highlight inserts somehow! */

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article,
aside,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

/*------------------------------------------------*/

/*-----------------[BASIC STYLES]-----------------*/

/*------------------------------------------------*/

body {
  font-family: 'Source Sans Pro', Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  background-color: #fff;
  line-height: 1.7em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  border-bottom: 1px solid;
  color: #00bc9c;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #00A383;
}

a:focus {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1em;
  font-weight: 700;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h2 {
  font-size: 42px;
  margin-bottom: 22px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 22px;
}

strong {
  font-weight: bold;
}

cite,
em,
i {
  font-style: italic;
}

pre,
code {
  font-family: Courier New, monospace;
  margin-bottom: 10px;
}

ins {
  text-decoration: none;
}

sup,
sub {
  height: 0;
  line-height: 1;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 0.8em;
}

sub {
  top: 0.3em;
}

dl {
  margin: 0 0 1.5em 0;
}

dl dt {
  font-weight: bold;
}

dd {
  margin-left: 1.5em;
}

blockquote p {
  padding-bottom: 0;
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
}

input[type=radio] {
  -webkit-appearance: radio;
}

input[type=text],
input[type=email],
input[type=password],
input.text,
input.title,
textarea,
select {
  background-color: #fff;
  border: 1px solid #bbb;
  padding: 2px;
  color: #253746;
}


input[type=text],
input[type=email],
input[type=password],
input.text,
input.title,
textarea,
select {
  margin: 0;
}

textarea {
  padding: 4px;
}

input::-webkit-input-placeholder {
  color: #A3b1bf;
}

input:-ms-input-placeholder {
  color: #A3b1bf;
}

input::placeholder {
  color: #A3b1bf;
}

textarea::-webkit-input-placeholder {
  color: #A3b1bf;
}

textarea:-ms-input-placeholder {
  color: #A3b1bf;
}

textarea::placeholder {
  color: #A3b1bf;
}

input[type=text],
input[type=email],
input[type=password],
textarea,
select {
  width: 100%;
  padding: 20px;
  margin: 4px;
  border: none;
  border-bottom: 2px solid #A3b1bf;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  font-size: 22px;
  font-weight: 600;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  height: 150px;
}

img#about-image {
  float: left;
  margin: 3px 8px 8px 0;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

/* IE6 */

*:first-child + html .clearfix {
  zoom: 1;
}

/* IE7 */

/*------------------------------------------------*/

/*---------------[MAIN LAYOUT INFO]---------------*/

/*------------------------------------------------*/

img {
  max-width: 100%;
  height: auto;
}

#ie8 img {
  width: auto;
}

::-moz-selection {
  background-color: #2b7de1;
  color: #FFF;
}

::selection {
  background-color: #2b7de1;
  color: #FFF;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
body,
input,
textarea,
select {
  font-family: 'Source Sans Pro', Helvetica, Arial, Lucida, sans-serif;
}

select > option {
  font-size: 22px;
}

p {
  font-size: 20px;
  color: #73879C;
  line-height: 1.5em;
}

h1 {
  font-size: 70px;
  margin-bottom: 22px;
}

h2,
h3,
h4,
h5 {
  color: #253746;
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.partner-logo {
  margin: -4% auto;
}

.header-title {
  margin: 100px auto 0;
  background-color: #2B7DE1;
}

.header-title .row {
  margin: 50px auto;
  text-align: center;
}

.header-title .row h1,
.header-title .row h2 {
  color: #FFF;
}

.header-title .row h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 0 24px;
}

.trusted-container {
  background-color: #F1F5F8;
  padding: 50px 0;
  text-align: center;
}

.trusted-container h3 {
  color: #B4B5B7;
  margin-bottom: 32px;
  text-align: center;
  font-weight: normal;
}

.newsletter-btn {
 background-color: #00BC9C;
    color: #FFF;
    font-size: 18px;
    text-transform: uppercase;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 700;
    width: 40px;
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
    left: -4px;
    position: relative;
}
.newsletter-btn:hover {
  background-color: #00A383;
}

.btn-general {
  font-weight: 700;
  background-color: #00BC9C;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  color: #FFF;
  padding: 10px 80px;
  
}

a.btn-general:hover,
a.btn-general:focus,
button.btn-general:hover,
button.btn-general:focus {
  background-color: #00A383;
  color: #FFF;
}

.tailored {
  background-image: url("/images/MyPrivacyPolicy-C2A-Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  text-align: center;
}

.tailored h3 {
  color: #FFF !important;
  font-size: 32px !important;
}

.tailored .minutes {
  text-align: right;
  padding: 0;
}

.tailored .start-now {
  margin-top: 8px;
  text-align: left;
}

.fl{
  float:left;
}
.fr{
  float:right;
}

.has-error .form-input {
  border-bottom: 1px solid #F44336 !important;
  box-shadow: 0 1px 0 0 #F44336 !important;
}

.has-success .form-input {
  border-color: #44A247;
}

.help-block {
  color: #F44336 !important;
}

.has-success {
  color: #44A247;
}

.anchor {
  display: block;
}

.slick-slide img {
  margin: 0 auto;
}

.arrow-left {
  left: -610px;
  top: 75px;
  cursor: pointer;
}

.arrow-right {
  right: -600px;
  top: -70px;
  cursor: pointer;
}



/*------------------------------------------------*/

/*---------------------[HEADER]-------------------*/

/*------------------------------------------------*/

#header_nav a {
  border-bottom: none;
}

#logo {
  border: none;
  margin-top: -12px;
}

#logo img.image-logo {
  height: auto;
  width: 100%;
  max-width: 250px;
}

.navbar-nav > li > a {
  color: #253746 !important;
  border-bottom: none;
  text-decoration: none;
  padding: 16px 20px 0 0;
  font-size: 15px;
  font-weight: 700;
}

nav.navbar.shrink #navbar li a {
  color: #FFF !important;
}

nav.navbar.shrink #navbar li a:hover,
nav.navbar.shrink #navbar li.active > a,
nav.navbar.shrink #navbar li.active > a:focus {
  color: #2b7de1 !important;
}

.create-btn {
  color: #00BC9C !important;
  padding: 11px 22px 11px 14px !important;
  background-color: #FFF;
  border-radius: 26px;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  font-weight: 700;
  border: 2px solid #00BC9C !important;
  font-size: 18px;
}

.create-btn:hover {
  background-color: #00BC9C !important;
  color: #FFF !important;
}

nav.navbar #navbar li a:hover,
nav.navbar #navbar li.active > a,
nav.navbar #navbar li.active > a:focus {
  color: #2b7de1 !important;
  background: transparent;
}

i.glyphicon.glyphicon-plus {
  font-size: 8px;
  font-weight: 400;
  top: -2px;
}

.navbar-default {
  padding: 26px;
  border: none;
  background-color: white;
  height: 100px;
}

#top-menu li > a:hover {
  color: #2b7de1;
}

#top-menu li.current-menu-ancestor > a,
#top-menu li.current-menu-item > a {
  color: #45a9db;
}

.navbar-default .navbar-toggle {
  border: none;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #253746;
  height: 4px;
  width: 28px;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

/*------------------------------------------------*/

/*---------------------[FOOTER]-------------------*/

/*------------------------------------------------*/

#main-footer #logo {
  color: #FFF !important;
  border: none;
}

#main-footer .footer-widgets div {
  margin-bottom: 14px;
}



#main-footer .footer-widgets h4 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
}

#main-footer .footer-widgets ul li {
  margin: 6px auto;
  font-weight: 600;
}

#main-footer .footer-widgets p {
  line-height: 1.2em;
  font-size: 14px;
  margin: 10px auto;
  color: #A3b1bf;
}

#main-footer hr {
  border-color: #a3b1bf;
}
#main-footer .subscribe-policy{
color: #5F6973;
}
#main-footer i.glyphicon.glyphicon-menu-right{
  font-size: 18px; top: 0; 
}

#footer-bottom {
  padding: 20px 15px 30px;
}



#footer-info {
  color: #A3b1bf;
  font-size: 14px;
  font-weight: 600;
}

#footer-info a {
  font-weight: 700;
  color: #666;
}

#main-footer .subscribe {
    width: 75%; 
    margin: 0;
    font-size: 18px;
    padding: 10px;
}

#main-footer .subscribe:focus{
  
  background: #FFFFFF;

}


.et-social-icon a:before {
  font-family: 'ETmodules';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#et-social-icons {
  text-align: right;
}

#et-social-icons li {
  display: inline-block;
  margin-left: 20px;
}

#et-social-icons li a {
  color: #596269 !important;
  font-size: 28px !important;
}

.et-social-icon a {
  display: inline-block;
  font-size: 24px;
  position: relative;
  text-align: center;
  transition: color 300ms ease 0s;
  color: #666;
  text-decoration: none;
}

#main-footer {
  background-color: #3D464D;
  padding-top: 50px;
}

#main-footer li a {
  color: #A3b1bf;
  font-size: 14px;
  border: none;
}

#main-footer p a {
  color: #5F6973;
}

#main-footer input {
  border-bottom: 0;
}



/*------------------------------------------------*/

/*---------------------[HOME PAGE]-------------------*/

/*------------------------------------------------*/

#home .home-hero-background {
  background-image: url("/images/MyPrivacyPolicy-Hero-Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#home .home-wrapper {
  margin-top: 110px;
  text-align: center;
}

#home .home-wrapper .sub-title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: 600;
}

#home h1 {
  color: #fff;
  margin-top: 92px;
 padding: 0 38px;
}

#home .learn-more {
  margin-top: 25px;
  margin-bottom: 100px;
}

#home .learn-more a {
  font-weight: 700;
  font-size: 16px;
  color: #FFF;
}

#home .getstarted-btn {
  padding: 16px 32px;
  background-color: #00BC9C;
  color: #FFF;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: -8px;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
}

#home .getstarted-btn:hover {
  background-color: #00A383;
}

#home ul.steps li {
  display: inline-block;
  font-weight: 700;
  width: 22%;
}

#home ul.steps li span {
  font-size: 48px;
  color: #00BC9C;
  font-weight: 600;
}

#home ul.steps li div.num {
  height: 88px;
  width: 88px;
  border-radius: 46px;
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  background-color: #FFF;
  padding: 30px;
  margin: 10px auto 20px;
}

#home ul.steps li div.text {
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  display: table;
  margin: 0 auto;
}

#home .steps-border {
  margin-top: -90px;
  width: 44%;
  border-top: 6px solid #FFF;
}

#home ul.steps li div.num.active {
  background-color: #00BC9C;
}

#home ul.steps li.divider {
  border-top: 6px solid #FFF;
  padding: 6px 0;
  margin: 0 -3px;
  width: 130px;
}

#home ul.steps li div.divider2 {
  border-top: 6px solid #00BC9C;
  padding: 6px 0;
  margin: 0 -3px;
}

#home ul.steps p {
  font-size: 22px;
  color: #FFF;
  font-weight: 600;
  margin-top: 16px;
}

#home .your-site {
  margin-left: -30px;
}

#home .quick-something-else {
  text-align: center;
  padding: 100px 0;
}

#home .quick-something-else h2 {
  margin-bottom: 60px;
}

#home .quick-something-else img {
  width: 70px;
  height: 70px;
}

#home .quick-something-else div {
  padding: 40px 24px 0;
}

#home .quick-something-else .sub-title {
  font-size: 22px;
  line-height: 1.5em;
  padding: 0 24px 60px;
  font-weight: 600;
}

#home .quick-something-else h3 {
  margin-bottom: 16px;
  color: #516B86;
  font-size: 28px;
}

#home .quick-something-else .content {
  padding: 0 25px 50px;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 600;
}


#home .homepage-url {
  height: 55px !important;
  font-size: 18px !important;
  border-bottom: 0 !important;
  width: 42% !important;
  box-shadow: none;
}

#home .simple-easy{
  background-color:#253746;
}


#home .seen-trusted .seen-trusted-wrapper{
  background-color:#F1F5F8;
}

#home .seen-trusted{
  padding: 50px 0;
}

#home .seen-trusted p {
  font-size: 30px;
  color: #B4B5B7;
  margin-bottom: 22px;
  text-align: center;
}

#home .seen-trusted div{
 /* padding: 50px 0;*/
}

#home .why-use{
  background-color:#FFF;
}

#home .service-wrapper .hero-tiles{
  vertical-align: bottom;
}

#home .generator-here .generator-title {
  text-align: center;
}

#home .generator-here .generator-title h2 {
  color: #FFF;
  padding: 50px 0;
}

#home .generator-here .generator-title p {
  color: #FFF;
  font-size: 24px;
  padding: 0 24px 60px;
  font-weight: 600;
}

#home .generator-here .generator-body {
  
}

#home .generator-here .generator-body div {
  margin-bottom: 50px;
  text-align: center;
}

#home .generator-here .generator-body div h3 {
  color: #FFF;
  margin-bottom: 22px;
  font-size: 28px;
}

#home .generator-here .generator-body div p {
  color: #FFF;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px 60px;
}

#home .quote-wrapper{
  background-color:#FFF;
}

#home .quote {
  text-align: center;
  margin: 100px auto;
}

#home .quote p {
  color: #253746;
  font-weight: 600;
  font-size: 16px;
  height: 250px;
  padding: 0 24px 25px;
}

#home .quote div {
  margin-bottom: 16px;
}

#home .quote img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-bottom: 10px;
}

#home .quote h4 {
  color: #b4b5b7;
  text-transform: uppercase;
  font-size: 16px;
}

#home .video-container{
  display: block;
  background-image: url("/images/MyPrivacyPolicy-AnimationLaptop.png"); 
  background-repeat: no-repeat; 
  background-position: center; 
  background-size: contain;
  height: 812px;
  margin-top: -75px;
}

#home .home-number{
  background-color:#00BC9C; padding: 67px 0 133px; text-align: center;
}

#tour .video-container{
  display: block;
  background-image: url("/images/MyPrivacyPolicy-AnimationLaptop.png"); 
  background-repeat: no-repeat; 
  background-position: center; 
  background-size: contain;
  height: 826px;
  margin-top: -50px;
}


.video-container video {
    margin-top: 110px;
    width: 75%;
    margin-left: 18px;
}

.trusted-img li {
  float: left;
  margin: 4px;
}

a.start-btn {
  color: #FFF;
  padding: 8px 80px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-weight: 700;
  border: 2px solid #FFF;
  font-size: 18px;
  text-transform: uppercase;
  background: transparent;
}

// a.start-btn:focus {
  // border: none;
// }

/*------------------------------------------------*/

/*---------------------[PRICING]-------------------*/

/*------------------------------------------------*/

#pricing .pricing-background {
  background-image: url("/images/MyPrivacyPolicy-Pricing-Background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0;
}

#pricing .pricing-section h2 {
  color: #FFF;
  font-size: 30px;
  margin-bottom: 0;
}

#pricing .pricing-section {
  border: 2px solid #2B7DE1;
  margin: 50px auto;
  width: 55%;
}

#pricing .pricing-section .title {
  background-color: #2B7DE1;
  padding: 25px 0;
  text-align: center;
}
#pricing .testimonials-wrapper{
  background-color:#2B7DE1;
}

#pricing .pricing-section .content {
  text-align: center;
  padding: 20px 4px;
  background-color: #FFF;
}

#pricing .pricing-section .content p {
  font-size: 22px;
  margin: 20px auto;
  font-weight: 600;
  line-height: 1.2em;
}

#pricing .show-read-more .more-text{
  display: none;
}

#pricing .pricing-section .price {
  color: #2B7DE1 !important;
  font-size: 64px !important;
  font-weight: 700 !important;
  padding: 0 0 16px !important;
  line-height: 0.7em;
}

#pricing .agency {
  color: #00BC9C;
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
  display: inline-block;
}

#pricing .pricing-section .one-off {
  color: #2B7DE1 !important;
  font-weight: normal !important;
  margin-top: -20px !important;
  font-size: 20px !important;
}

#pricing .price-get-started {
  margin-top: 20px;
  text-align: center;
}

#pricing .price-faq {
  margin-top: 50px;
}

#pricing .price-faq h2 {
  text-align: center;
  color: #253746;
  margin-bottom: 50px;
}

#pricing .price-faq div {
  margin-bottom: 22px;
}

#pricing .price-faq h4 {
  margin-bottom: 16px;
  font-weight: 600;
}

#pricing .price-faq div p {
  padding-bottom: 22px;
}

.slick-slide {
  padding: 0 12px;
  margin: 0 auto;
}

.testimonials {
  text-align: center;
  margin: 100px auto;
}

.testimonials img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-bottom: 10px;
}

.testimonials h4 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 50px;
}

.testimonials p {
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  padding: 0 24px 25px;
  height: 225px;
}

/*------------------------------------------------*/

/*---------------------[PARTNER]-------------------*/

/*------------------------------------------------*/


#partners h2 {
  color: #253746;
  font-size: 42px;
  font-weight: 700;
}

#partners .mpp-pricing li {
  font-size: 22px;
  line-height: 1.5em;
  margin-left: 24px;
  color: #73879C;
}

#partners .form-partner {
  padding: 100px 162px 50px;
  text-align: center;
}

#partners .partner-section {
  padding: 100px 0;
}
#partners .partner-section a{
  margin-top:50px;
}
#partners .form-partner p, #partners .partner-section .about-partnership p{
  font-size: 22px; margin-bottom: 22px; 
}

#partners .partner-section .about-partnership {
  margin-bottom: 100px;
}


#partners .form-partners{
  margin-top: 50px;
}
#partners #form-partners{
  margin: 0;
}

#partners .flash_success{
  background-color:#00BC9C; padding: 50px 0;
}

#partners .flash_success img{
  margin-bottom: 10px;
}

#partners .flash_success h3, 
#partners .flash_success p, 
#partners .flash_success a{
  color: #FFF;
}

ul.partners-form {
  padding: 26px;
}

ul.partners-form li {
  margin: 24px auto;
}

#partners .inputfile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#partners .inputfile + label {
  max-width: 80%;
  font-size: 22px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 12px;
  color: #A3B1BF;
  border: 2px solid #A3B1BF;
  position: relative;
    left: 0 !important;
}

#partners .partner-dashboard {
  background-color: #00BB9C;
  height: 928px;
  text-align: center;
  padding: 100px 0;
}

#partners .partner-dashboard h2,
#partners .partner-dashboard p,
#partners .partner-dashboard h3 {
  color: #fff  !important;
}

#partners .testimonials-wrapper{
  background-color:#2B7DE1;
}

#partners .partner-dashboard .sub-title {
  font-weight: 600;
  padding: 0 24px 40px;
  font-size: 24px;
}

#partners .feature {
  padding: 75px 2% 0;
  text-align: center;
  width: 23%;
  margin-left: 220px;
}

#partners .feature p {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 18px;
}

#partners .feature h3 {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
}

#partners div.partner-referrence {
  position: relative;
  width: 1680px;
  height: 200px;
}

#partners div.computer {
  position: absolute;
  top: 26px;
  right: 25px;
  width: 1100px;
  height: 668px;
  overflow: hidden;
}

#partners div.computer img {
  vertical-align: bottom;
  width: 100%;
}

#partners .support-box {
  background-color: #fff;
  padding: 32px 0 18px;
  text-align: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 12px 6px;
}

#partners .support-box .free-guide {
  padding: 10px 0px;
  width: 100%;
}

#partners i.glyphicon.glyphicon-menu-right {
  color: #00BC9C;
  font-size: 14px;
  font-weight: 700;
  margin-right: 4px;
}

#partners .form-input {
  text-align: left;
}

#partners .input-field label {
  left: 1.2em;
}

#partners .logo-alerts{
  margin-top: 50px;
}



.material-tooltip {
  padding: 10px;
  font-size: 22px;
  z-index: 2000;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  min-height: 36px;
  line-height: 120%;
  opacity: 0;
  position: absolute;
  text-align: center;
  overflow: hidden;
  left: 0;
  top: 0;
  pointer-events: none;
  visibility: hidden;
  font-weight: 600;
  max-width: 220px;
  width: 220px;
}

.backdrop {
  position: absolute;
  opacity: 0;
  height: 7px;
  width: 14px;
  border-radius: 0 0 50% 50%;
  background-color: #2b7de1;
  z-index: -1;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  visibility: hidden;
}

#partners .why-wrapper{
  margin: 30px auto;
}

#partners .why-tooltip {
  font-weight: 600;
  font-size: 22px;
  color: #A3b1bf;
  border: none;
}

#partners .why-tooltip + .tooltip > .tooltip-inner {
  background-color: #2b7de1;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  max-width: 220px;
  width: 220px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

#partners .why-tooltip + .tooltip > .tooltip-arrow {
  border-bottom-color: #2b7de1;
  color: #2b7de1;
}

#partners .why-tooltip + .tooltip.in {
  opacity: 1;
  filter: alpha(opacity=1);
}

#partners .no-account {
  margin-top: 50px;
}

/*------------------------------------------------*/

/*---------------------[FAQ]----------------------*/

/*------------------------------------------------*/
#faq .faq-titles{
background-color: #F1F5F8; padding: 50px 0; 
}

#faq .faq-section p {
  margin-bottom: 22px;
  line-height: 1.4em;
  margin-left: 30px;
}

#faq .faq-article{
  padding:50px 0;
}

#faq ul li {
  float: left;
      margin: 0 20px;
}

#faq ul li a {
  border: none;
  font-size: 22px;
  font-weight: 600;
  color: #a3b1bf;
  text-transform: uppercase;
}

#faq ul li.active a {
  border-bottom: 4px solid #00BC9C;
  color: #253746;
}

#faq ul li a:hover,
#faq ul li a:focus {
  border-bottom: 4px solid #00BC9C;
  color: #253746;
}

#faq h3 {
  margin-bottom: 22px;
}

#faq h4 {
  font-weight: 600;
  margin-bottom: 16px;
}

#faq .faq-content{
  padding: 40px 0;
}

#faq .faq-feedback{
  background-color: #F1F5F8; padding: 100px 0;
}

.feedback .row div > div {
  padding: 12px;
}

.feedback a {
  border: none;
  font-weight: 600;
}

.feedback img {
  margin-top: 20px;
}

.feedback p {
  font-size: 18px;
}

.feedback .divider {
  border-right: 2px solid #a3b1bf;
}

/*------------------------------------------------*/

/*---------------------[GENERATE]-----------------*/

/*------------------------------------------------*/

#generate .step1-form {
  margin-bottom: 50px;
  text-align: center;
}

#generate .step1-form form,
#generate .payment {
  padding: 50px 130px;
}

#generate .step1-form .intro {
  padding: 0 130px;
}

#generate .step1-form .intro p {
  font-weight: 600;
  font-size: 24px;
}

#generate .step1-form .intro span {
  word-wrap: break-word;
  color: #00BC9C;
}

#generate .step1-note p{
  margin-top: 200px;
}

#generate .step1-note a{
  color: #73879C;
}

#generate .steps-continue{
  padding-bottom:100px;
}


#generate ul.steps li {
  display: inline-block;
  font-weight: 700;
}

#generate ul.steps li span {
  font-size: 48px;
  color: #FFF;
}

#generate ul.steps li div.num {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  background-color: #CFD8DD;
  padding: 26px;
  z-index: 999;
}

#generate ul.steps li div.num.active {
  background-color: #00BC9C;
}

#generate ul.steps li div.divider.active {
  border-color: #00BC9C;
}

#generate ul.steps li div.divider {
  border-top: 4px solid #CFD8DD;
  width: 118px;
  margin: -12px -3px;
  z-index: 0;
}

#generate .text-url{
  color:#00BC9C;
}

#generate .favourite-wrapper{
  background-color: #2B7DE1;
}
#generate .services-wrapper{
  background-color: #FFF;
}

#generate .select-deselect{
  color: #A3b1bf;
}

#generate ul.steps p {
  font-size: 20px;
  color: #FFF;
}

#generate .form-input {
  background: transparent;
}

#generate .service h4 {
  padding: 3px;
  font-size: 17px;
  color: #73879C;
  line-height: 1.2em;
}

#generate .service img {
  text-align: center;
      padding: 6px 0;
}

#generate .selected {
  box-shadow: 0 0 0 3px #00bc9c !important;
}

#generate ul.favorites li.fav-text {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  margin: 84px -100px 0;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

#generate ul.favorites li,
#generate ul.services li {
  float: left;
  margin: 16px;
}

#generate .lessmore-divider{
  font-weight: 700;
}

#generate .less,
#generate .more {
  color: #2B7DE1;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 6px;
}

#generate .select,
#generate .deselect {
  color: #00BC9C;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 6px;
}

#generate .lessbtn.lessinfo {
  z-index: -1;
  top: 34px;
}

#generate .lessbtn,
#generate .morebtn {
  height: 0;
}

#generate .less,
#generate .more {
  z-index: 1;
}

#generate .serviceinfo {
  height: 124px;
}

#generate #service-error{
  margin-bottom: 50px;
  text-align: center;
}

#generate .service {
  width: 160px;
  background-color: #FFF;
  padding: 8px 3px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  height: 168px;
  cursor: pointer;
}

#generate .service p {
  line-height: 1.3em;
  font-weight: 600;
  padding: 4px 5px;
  font-size: 13px;
}

#generate .payment-form {
  padding: 0 140px;
}

#generate .state {
  width: 48%;
  float: left;
}

#generate .postcode {
  width: 48%;
  float: right;
}

.steps-wrapper {
  padding: 50px 0;
  text-align: center;
}

#generate .payment-option {
  background-color: #253646;
  width: 30%;
  margin: 50px auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#generate .payment-option img.selected {
  border: 2px solid #00BC9C;
}

#generate .payment h3 {
  margin-top: 50px;
}

#generate .payment .services.col-md-6 {
  margin: 20px auto;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
}

#generate .payment .services.col-md-6:hover {
  background-color: #F6F7FB;
}

#generate .btn.btn-general {
  padding: 20px 80px;
  font-size: 18px;
}

#generate .payment .services.col-md-6 span.list {
  font-size: 20px;
  color: #73879C;
  margin-left: 5px;
  font-weight: 600;
}

.morebtn.moreinfo, .lessbtn.lessinfo{
    position: relative;
    -webkit-transition: top 1s;
    -moz-transition: top 1s;
    -o-transition: top 1s;
    transition: top 1s;
}

.service-mb-mid {
  display:flex;
  align-items:center;
}

.service-desktop{
  padding: 50px 0; 
  text-align: center;
}

#generate .generate-buttons hr{
  border-top: 2px solid #A3B1bf;  margin: 50px auto;
}

#generate .step3-services{
  margin: 10px auto;
}
#generate .step3-services img{
  vertical-align: middle;
}

#generate .payment hr{
  border-top: 2px solid #00BC9C; margin: 10px auto;
}

.box,
.credit {
  display: none;
}

.cc-selector input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
            border: none;
            visibility:hidden;
             display: none;
}

.cc-selector-2 input{
    position:absolute;
    z-index:999;
}

.paypal{background-image:url("/images/MyPrivacyPolicy-PayPal-Payment-Icon-Active.png");}
.creditcard{background-image:url("/images/MyPrivacyPolicy-CreditCard-Payment-Icon-Active.png");}

.cc-selector-2 input:active +.drinkcard-cc, .cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector-2 input:checked +.drinkcard-cc, .cc-selector input:checked +.drinkcard-cc{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
            border: 2px solid #00BC9C;
  background-color: #FFF;
}
.drinkcard-cc{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    width:100px;height:70px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
             border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  margin: 8px auto 2px;
  background-position: center;
  background-size: contain;
}
.drinkcard-cc:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
}


.cardtype {
  height: 28px;
  background-repeat: no-repeat;
  float: right;
  width: 32px;
  position: absolute;
  right: 32px;
  top: 28px;
}

#cardnumber-error + div.cardtype {
  dispay: none;
}

#generate .remove {
    color: red;
    float: right;
    font-size: 22px;
    padding-top: 14px;
}

#generate .cloudflare {
  text-align: center;
  font-weight: 600;
  margin: 20px;
}

#generate .terms {
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

#generate .terms p{
  font-weight: 600;
}

#generate .service-mobile{
  display: none;
}


#generate .service-desktop{
  display: block;
}

#generate h3.step3-header{
  border-bottom: 2px solid #00BC9C;
  padding-bottom: 10px;
  margin-bottom: 22px;
}

/*------------------------------------------------*/

/*-----------------------[NEWS]-------------------*/

/*------------------------------------------------*/

#pinBoot {
  position: relative;
  max-width: 100%;
  width: 100%;
  min-height: 540px;
  margin-top: 58px;
}

#pinBoot p {
  line-height: 1.4em;
  font-size: 18px;
}

#pinBoot p,
#pinBoot h4 {
  padding: 0 16px 16px;
}

#news{
  background-color: #F1F5F8;
}

#news .grid .content {
  padding: 0 8px 22px;
}

#news .category {
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 12px;
  color: #a3b1bf;
}

#news button.btn-filter {
  text-transform: uppercase;
  border: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  color: #a3b1bf;
  margin-right: 20px;
  padding: 0 1px;
  line-height: 1.5em;
}

#news .is-checked,
#news button.btn-filter:hover {
  border-bottom: 4px solid #00BC9C !important;
  color: #253746 !important;
}

#news .news-header {
  text-align: center;
  color: #FFF;
}

#news .news-header h1 {
  font-size: 50px;
  margin-top: 20px;
}

#news .news-header h4 {
  color: #FFF;
  text-transform: uppercase;
  font-weight: 600;
}

#news .news-inner {
  padding: 100px 186px;
  text-align: center;
}

#news .news-inner p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

#news .news-inner ul {
  font-weight: 600;
  font-size: 20px;
  color: #73879C;
}

#news .news-inner li {
  line-height: 1.5;
}

#news .news-inner li:last-child {
  margin-bottom: 16px;
}

#news blockquote {
  border-left: 2px solid #00BC9C;
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  color: #73879c;
  line-height: 1.6em;
}

#news a.article-btn {
  color: #FFF;
  padding: 8px 64px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-weight: 700;
  border: 2px solid #FFF;
  font-size: 18px;
  text-transform: uppercase;
  background: transparent;
}

#news .btn-general {
  padding: 10px 46px;
}

#news .element-item {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  width: 350px;
  text-align: center;
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: #FFF;
}

#news .element-item .featured-img {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  cursor: pointer;
}

#news .article-background {
  margin-top: 106px;
  padding: 100px 0;
  background-position: center center;
  background-size: cover;
}

#news #share{
  text-align: center;
  padding: 50px;
}

.white-panel {
  position: absolute;
  background: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
}

/*
stylize any heading tags withing white-panel below
*/

.white-panel h1 {
  font-size: 1em;
}

.white-panel h1 a {
  color: #A92733;
}

.white-panel:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  margin-top: -5px;
  transition: all 0.3s ease-in-out;
}

ul.news-category {
  padding-left: 15px;
}

ul.news-category li {
  float: left;
  text-transform: uppercase;
  padding: 20px 39px 20px 0;
  font-size: 18px;
  color: #73879C;
  font-weight: 700;
}

h5 {
  color: #000;
}

#news .news-section {
  margin: 50px auto;
}

#news .news-section .news-category {
  margin-bottom: 32px;
}

#news .news-content {
  text-align: center;
  margin-bottom: 36px;
}

#news .news-article {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

#news .news-article p {
  padding: 4px;
}

.news-content .description {
  /* border: 1px solid red; */
  margin-top: -15px;
}
#support .select-topic{
  font-size: 22px; margin-bottom: 50px; font-weight: 600;

}

#support .support-box {
  background-color: #fff;
  padding: 22px;
  text-align: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 18px 18px;
  width: 276px;
  height: 276px;
  cursor: pointer;
}

#support .flash_success{
  background-color:#00BC9C; padding: 50px 0;
}

#support .flash_success img{
  margin-bottom: 10px;
}

#support .flash_success h3, 
#support .flash_success p, 
#support .flash_success a{
  color: #FFF;
}

#support .support-box p {
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 600;
}

#support .support-box img {
  margin-bottom: 12px;
  width: 100px;
}

#support .support-box a {
  font-weight: 600;
}

#support .support-wrapper{
  background: #F1F5F8;
}

#support .support-section {
  padding: 100px 100px 50px;
  text-align: center;
}

#support .support-form {
  padding: 0 140px 50px;
}

#support #description{
  min-height: 150px;
   margin-bottom: 25px;
}

#support .feedback-form {
  background-color: #FFF;
  margin-top: 50px;
}

#support .support-box.active {
  box-shadow: 0 0 0 3px #00bc9c !important;
}

#support .support-topic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#support h4 {
  color: #2b7de1;
  font-weight: 600;
  margin-bottom: 12px;
}

#support .read{
  color: #73879C; font-size: 14px; margin-top: 12px; font-weight: 600;
}

#support .feedback-form a{
  color: #73879C;
}

ul.support-form li {
  margin: 24px auto;
}

.dashboard-header {
  padding: 100px 0 180px;
}

#my-account ul.my-account-tab li a {
  width: 100%;
  background-color: #a3b1bf;
}

#my-account ul.my-account-tab li a.active,
#my-account ul.my-account-tab li a:hover {
  background-color: #00BC9C;
}

#my-account form {
  margin-top: 26px;
}

#my-account .fieldset {
  border: 1px solid #a3b1bf;
  padding: 48px;
  margin: 26px auto 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
}

#my-account ul.my-account-tab li {
  margin-bottom: 16px;
}



#my-account ul.my-account-year li {
  float: left;
  margin-right: 24px;
  font-size: 22px;
  font-weight: 600;
}

#my-account ul.my-account-year li a {
  color: #73879C;
  border: none;
}

#my-account ul.my-account-year li.active {
  border-bottom: 2px solid #00BC9C;
}

#my-account ul.my-account-year li.active a {
  color: #253746;
}



#my-account h2 {
  color: #253746;
  font-size: 48px;
}

#my-account h3 {
  margin-bottom: 22px;
}

#my-account p {
  font-size: 22px;
  font-weight: 600;
}

#my-account .feedback p{
  font-size: 18px;
}

#my-account .dashboard-wrapper {
  margin-top: 25px;
  cursor: pointer;
}

#my-account .dashboard-wrapper .empty-policies{
  text-align: center;
    text-transform: uppercase;
}

#my-account .dashboard-wrapper .empty-policies p{
  margin-bottom: 25px;
}

#my-account .dashboard-wrapper .dashboard-info {
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 30px;
}

#my-account .dashboard-wrapper .dashboard-info .dashboard-url {
  padding: 80px 20px;
  background-image: url("/images/MyPrivacyPolicy-tile-bg.jpg");
  background-position: center;
  background-size: cover;
  text-align: center;
  word-wrap: break-word;
}

#my-account .dashboard-wrapper .dashboard-info .dashboard-url p {
  color: #fff;
  font-weight: 700;
}

#my-account .dashboard-wrapper .dashboard-year {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 25px 0;
  background-color: #FFF;
  text-align: center;
}

#my-account .dashboard-wrapper .website-policy.active {
  box-shadow: 0 6px 0px #00bc9c;
}



#my-account .tour-buttons h4 {
  margin: 10px auto;
  font-weight: 600;
}

#my-account .tour-buttons {
  background-color: #fff;
      padding: 10px 0;
    margin: 10px auto;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  float: left;
  width: 100%;
}

#my-account .help-feedback p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 22px;
}

#my-account .help-feedback div {
  margin: 25px auto;
}

#my-account .generated-policy {
  padding: 20px;
  margin-top: -9px;
}

#my-account .generated-policy h1,
#my-account .generated-policy strong {
  font-size: 22px;
  color: #253746;

}



#my-account .generated-policy ul li strong{
  font-size: 19px;
}

#my-account .generated-policy p {
  font-size: 20px;
  margin-bottom: 22px;
}


#my-account .generated-policy ul{
  padding: 0 0 20px 50px;
}

#my-account .generated-policy ul li {
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
    color: #73879C;
    list-style-type: disc;
    line-height: 1.3em;
    
}
#my-account .tour-buttons .btn-general{
  padding: 10px;
    width: 100%;
}

#my-account .tour-buttons .btn-copy {
  background-color: #2B7DE1;
  border: 2px solid #2B7DE1;
}

#my-account .tour-buttons .btn-send {
  background-color: #FFF;
  color: #2B7DE1;
  border: 2px solid #2B7DE1;
}

#my-account .tour-buttons .btn-hide {
  border: 2px solid #00BC9C;
}

#my-account .tour-buttons pre {
  white-space: pre-line;
  white-space: -moz-pre-line;
  white-space: -pre-line;
  white-space: -o-pre-line;
  word-wrap: break-word;
  font-size: 18px;
  color: #73879C;
  font-weight: 600;
}

#my-account .empty-wrapper{
  padding: 50px 0; background: #F1F5F8;
}

#my-account .feedback-wrapper{
  background-color: #FFF; padding: 100px 0;
}

#simplified-html, #simplified-formatted, #extended-html{
  display: none;
}

.guide {
  border: 1px solid red !important;
}

.guide2 {
  border: 1px solid blue !important;
}

/*------------------------------------------------*/

/*-----------------------[TOUR]-------------------*/

/*------------------------------------------------*/



#tour .minutes h1 {
  color: #253746;
}

#tour .minutes p {
  font-size: 24px;
  padding: 0 24px 50px;
  font-weight: 600;
}

#tour .minutes .desktop img {
  vertical-align: bottom;
}

#tour .services .sub-title p {
  font-size: 24px;
  padding: 0 240px 40px;
}

#tour .services-wrapper{
  padding: 100px 0; background-color:#2b7de1; text-align:center;
}

#tour .services h2,
#tour .services p,
#tour .services span {
  color: #fff;
}

#tour .services span {
  font-weight: 600;
  text-align: left;
  line-height: 1.4em;
}

#tour .services ul.service-img li {
  float: left;
  margin: 10px;
}

#tour .services ul.service-img li img {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

#tour .create-minutes{
  padding: 100px 0; 
  background-color:#F1F5F8; 
  text-align:center;
  margin-top: 50px;
}
#tour .try-wrapper{
  margin-top: 50px;
}
#tour .try {
  border: 2px solid #fff;
  padding: 12px 46px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

#tour h3 {
  margin-bottom: 22px;
  color: #516B86;
  font-size: 28px;
}

#tour .policies-wrapper{
  padding: 100px 0 50px; background-color:#FFF; text-align:center;
}

#tour .policies img {
  margin-bottom: 12px;
  width: 70px;
  height: 70px;
}

#tour .policies p {
  padding: 0 24px 60px;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 600;
}

#tour .dashboard-wrapper{
  padding: 100px 0; background-color:#F1F5F8;text-align:center;
}



#tour hr {
  border: 1px solid #a3b1bf;
  margin: 50px auto;
}

#tour .more {
  color: #2B7DE1 !important;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 6px;
}

#tour .select {
  color: #00BC9C !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  margin-left: 6px;
}

#tour .selected {
  box-shadow: 0 0 0 3px #00bc9c !important;
}

#tour .service {
  float: left;
  margin: 10px;
  width: 106px;
  height: 112px;
  background-color: #FFF;
  padding: 4px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

#tour h4 {
  font-size: 12px;
  color: #73879C;
  font-weight: 600;
}

#tour .lessbtn,
#tour .morebtn {
  height: 85px;
}

#tour .desc {
  font-size: 11px;
  color: #73879C !important;
  line-height: 1.2em;
  font-weight: 600;
  padding: 6px 6px 4px;
}

#tour .dashboard .dashbox {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

#tour .dashboard p.sub-title {
  text-align: center;
  font-size: 24px;
  padding: 0 24px 60px;
}

#tour .dashboard h3 {
  text-align: left;
}
#tour .dashboard h3{
  margin-top: 100px;
}

#tour .dashboard p {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}

#tour .morebtn img {
  padding: 6px 0 2px;
  width: 50px;
}

#privacy .privacy-header {
  text-align: center;
  color: #FFF;
}

#privacy .privacy-inner {
  padding: 50px 0;
}

#privacy .privacy-inner h4, #privacy .privacy-inner h3 {
  margin-bottom: 22px;
}

#privacy .privacy-inner p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

#privacy .privacy-inner ul {
  font-weight: 600;
  font-size: 20px;
  color: #73879C;
}

#privacy .privacy-inner li {
  line-height: 1.5;
  display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 50px;
}

#privacy .privacy-inner li:last-child {
  margin-bottom: 16px;
}

#privacy a.article-btn {
  color: #FFF;
  padding: 8px 64px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-weight: 700;
  border: 2px solid #FFF;
  font-size: 18px;
  text-transform: uppercase;
  background: transparent;
}

#privacy .btn-general {
  padding: 10px 46px;
}

/**********************************

Name: cmxform Styles

***********************************/

form.cmxform {
  width: 370px;
  font-size: 1.0em;
  color: #333;
}

form.cmxform legend {
  padding-left: 0;
}

form.cmxform legend,
form.cmxform label {
  color: #333;
}

form.cmxform fieldset {
  border: none;
  border-top: 1px solid #C9DCA6;
  background-color: #F8FDEF;
}

form.cmxform fieldset fieldset {
  background: none;
}

form.cmxform fieldset p,
form.cmxform fieldset fieldset {
  padding: 5px 10px 7px;
}

form.cmxform label.error,
label.error,
.help-inline {
  /* remove the next line when you have trouble in IE6 with labels in list */
  float: none;
  color: #F44336;
  padding-left: .5em;
  vertical-align: top;
  display: block;
  text-align: left;
}

div.error {
  display: none;
}

input {
  border: 1px solid black;
}

input.checkbox {
  border: none;
}

input.error {
  border-bottom: 2px solid #f44336;
}

input.error::-webkit-input-placeholder {
  color: #f44336;
}

input.error:-ms-input-placeholder {
  color: #f44336;
}

input.error::placeholder {
  color: #f44336;
}

input.valid {
  border-bottom: 2px solid #44a247;
}

.has-success .form-control-feedback {
  color: #44a247;
  margin-top: 14px;
}

.has-error .form-control-feedback {
  color: #f44336;
  margin-top: 14px;
  box-shadow: none !important;
}

form.cmxform .gray * {
  color: gray;
}


.ui-autocomplete {
  z-index: 5000;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content {
  background-color: #2B7DE1;
}

.modal-header {
  border: none;
  padding: 5px 15px;
}

.modal-header span {
  color: #FFF;
  font-size: 36px;
  font-weight: normal;
}

.modal-header .close {
  opacity: 1;
}

.modal-body {
  padding: 0px 80px 40px;
}

.modal-body h1,
.modal-body p,
.modal-body span,
.modal-body a {
  color: #FFF;
  font-weight: 600;
}


.modal .form-group {
  margin: 0;
}

.modal .input-field{
 margin-top: 30px; 
}

.modal-body p {
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.modal-body h1 {
  font-size: 42px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-content input::-webkit-input-placeholder {
  color: #FFF;
}

.modal-content input:-ms-input-placeholder {
  color: #FFF;
}

.modal-content input::placeholder {
  color: #FFF;
}

.modal-content input {
  background: transparent;
  color: #FFF;
  border-bottom: 2px solid #FFF;
  box-shadow: none !important;
  caret-color: #FFF;
}

.modal-content input:focus {
  border-color: inherit;
  box-shadow: none;
}

.modal-content .modal-btn {
  margin-bottom: 6px;
  background: transparent;
  color: #FFF;
  padding: 12px 40px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-weight: 700;
  border: 2px solid #FFF;
  font-size: 18px;
  text-transform: uppercase;
}

.modal .has-error .help-block {
  background-color: #FFF;
  margin: 4px;
  width: 100%;
}

.modal .form-group {
  padding: 2px 20px;
}

.modal .input-field label {
  color: #FFF !important;
  padding: 2px 24px;
}

.modal input:not([type]).invalid,
.modal input:not([type]):focus.invalid,
.modal input[type=text].invalid,
.modal input[type=text]:focus.invalid,
.modal input[type=password].invalid,
.modal input[type=password]:focus.invalid,
.modal input[type=email].invalid,
.modal input[type=email]:focus.invalid {
  border-bottom: 2px solid #F44336 !important;
}

.modal input:not([type]).valid,
.modal input:not([type]):focus.valid,
.modal input[type=text].valid,
.modal input[type=text]:focus.valid,
.modal input[type=password].valid,
.modal input[type=password]:focus.valid,
.modal input[type=email].valid,
.modal input[type=email]:focus.valid {
  border-bottom: 2px solid #FFF;
}

.modal input:not([type]):focus:not([readonly]),
.modal input[type=text]:focus:not([readonly]),
.modal input[type=password]:focus:not([readonly]),
.modal input[type=email]:focus:not([readonly]) {
  border-bottom: 2px solid #FFF;
}

.modal .has-error .form-control,
.modal .has-error:focus {
  border-color: #F44336 !important;
}

@media (max-width: 1199px) {
  #partners .support-box .free-guide a.btn {
    padding: 10px 0;
    width: 80%;
  }
  #home .quote p, .testimonials p{
        height: 300px;   
  }
  
  #home .video-container{
    height: 710px;
  }
  #tour .video-container{
    height: 722px;
  }
   .video-container video{
        margin-left: 14px;
   }
   
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .nav.loggedin a {
    padding: 16px 10px 0 0 !important;
  }

  .nav.loggedin a.create-btn {
    padding: 11px 12px 11px 10px !important;
  }
}

@media (max-width: 1199px) {

}

@media (max-width: 1100px) {
  .navbar-nav > li > a {
    padding: 16px 10px 0 0;
  }
}

@media (max-width: 1024px) {
  .navbar-default {
    padding: 26px 0;
  }

  #news .element-item {
    margin-right: 50px;
    margin-bottom: 50px;
    width: 410px;
  }

  #generate ul.favorites li.fav-text {
    margin: 80px -66px 0;
  }


  #generate .payment-option {
    width: 40%;
  }

  #support .support-box {
    padding: 16px;
    margin: 18px 16px;
    width: 265px;
    height: 265px;
  }

  #support .support-section {
    padding: 0 20px 50px;
  }

  #support .support-form {
    padding: 0 60px 50px;
  }

  #tour .services .sub-title p {
    padding: 0 138px 40px;
  }

  #partners .feature {
    margin-left: 50px;
  }

  #partners div.computer {
    right: 130px;
  }
  
  
  video{
    margin-top: 160px;
  }
}

@media (min-width: 891px) and (max-width: 1100px) {
  .create-btn {
    margin: 5px 6px 2px 0px;
    padding: 6px 14px 8px 8px !important;
  }
}

.navbar-container {
  width: 100%;
}

@media (min-width: 768px) {
  .navbar-nav {
    margin-left: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #generate .payment-option {
    width: 38%;
  }

  #news .element-item {
    width: 316px;
    margin-right: 20px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  #generate ul.favorites li.fav-text {
    margin: 80px -62px 0;
  }
}
@media (max-width: 992px) {
  #home .video-container{
        height: 708px;
  }
  #tour .video-container{
    height: 722px;
  }
  
  .video-container video{
    margin-left: 14px;
  }
} 

@media (max-width: 991px) {
  .shrink span.icon-bar {
    background-color: #FFF !important;
  }
  
  #home .quote p, .testimonials p {
    height: auto;
}
#my-account ul.my-account-tab li:last-child {
  margin-bottom: 50px;
}

#faq ul li {

      margin: 10px 20px;
}

  #home ul.steps li {
    width: 30%;
  }

  #home .steps-border {
    width: 70%;
  }

  #generate .step1-form form,
  #generate .payment {
    padding: 50px 50px;
  }

  #generate .state {
    margin-left: 0;
  }

  #generate .postcode {
    margin-right: 0;
  }

  #generate ul.favorites li,
  #generate ul.services li {
    margin: 16px 38px;
  }

  #generate ul.favorites li.fav-text {
    margin: 80px -60px 0;
  }

  #partners .feature {
    width: 100%;
    margin: 10px auto;
    padding: 72% 10% 0;
  }

  #partners .partner-dashboard {
    height: 100% !important;
  }

  #partners div.computer {
    top: 10px;
    right: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: block;
  }

  #partners div.partner-referrence {
    width: 100%;
    height: auto;
  }

  #partners .feature p:last-child {
    margin-bottom: 50px;
  }

  #support .support-section {
    padding: 0 0px 50px;
  }

  #support .support-box {
    width: 216px;
    height: 272px;
    margin: 18px 10px;
    padding: 7px;
  }

 

  .tailored .start-now {
    margin-top: 2px;
    text-align: center;
  }

  .tailored .minutes {
    text-align: center;
  }

  .tailored .minutes h3 {
    margin-bottom: 22px;
  }

  #home .quote div {
    margin-bottom: 50px;
  }

  #home .quote div:last-child {
    margin-bottom: 0;
  }
  
#my-account .tour-buttons h4{
  margin: 0 15px 20px;
}
  

  .feedback .divider {
    border-right: none;
  }

  .arrow-right {
    right: -410px;
    top: -50px;
  }

  .arrow-left {
    left: -410px;
    top: 55px;
  }

  .trusted p {
    margin-top:50px;
  }

  .seen-trusted div div{
    padding:0;
  }
  .seen-trusted img{
    margin: 0 auto;  
  }
  
  #home .video-container{
    height: 592px;
  }
  #tour .video-container{
    height: 608px;
  }
  
  .video-container video{
        margin-left: 10px;
  }
  
}

@media (min-width: 891px) and (max-width: 991px) {
  #logo img {
    max-width: 220px;
    margin-top: 2px;
  }
}

@media (max-width: 890px) {
  .shrink .navbar-collapse.in {
    background-color: rgba(37, 56, 70, 0.95) !important;
  }

  .navbar-default {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }

  nav.navbar.shrink {
    background-color: #263F55 !important;
  }

  ul.navbar-nav {
    float: none !important;
  }

  .navbar-nav li {
    float: none;
  }

  .navbar-nav li a {
    font-size: 22px;
    padding: 12px 0;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-header .collapse,
  .navbar-toggle {
    display: block !important;
  }

  .navbar-header {
    float: none;
  }

  #navbar {
    padding: 20px 0;
    text-align: center;
    background-color: #EAF3FC;
    height: 100% !important;
    left: 0;
    position: fixed;
    width: 100%;
    margin: 20px auto;
    max-height: none;
    overflow: hidden;
  }

  #nav-menu1 {
    margin: 0;
  }

  #nav-menu2 {
    margin: 104px auto;
  }

  .navbar-toggle .icon-bar {
    position: relative;
    transition: all 500ms ease-in-out;
  }

  .navbar-toggle.active .icon-bar:nth-of-type(1) {
    top: 6px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  .navbar-toggle.active .icon-bar:nth-of-type(2) {
    background-color: transparent !important;
  }

  .navbar-toggle.active .icon-bar:nth-of-type(3) {
    top: -10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .create-btn {
    background-color: #00BC9C;
    color: #FFF !important;
    padding: 11px 82px 11px 72px !important;
  }

  .header-btn {
    position: absolute;
    top: -148px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
  }
}

@media (max-width: 768px) {
  .tailored h3 {
    margin-bottom: 22px;
  }

  .gallery-img li {
    margin: 20px;
  }

  #pricing .pricing-section {
    border: 2px solid #2B7DE1;
    margin: 82px auto;
    width: initial !important;
  }

  #partners .support-box {
    margin: 25px 50px;
    padding: 32px 100px;
  }

  #tour .services .sub-title p {
    padding: 0 40px 40px;
  }

  #tour .service {
    margin: 10px;
    width: 138px;
    padding: 4px;
    height: 142px;
  }

  #tour .morebtn,
  #tour .lessbtn {
    height: 102px;
  }

  #tour h4 {
    font-size: 16px;
  }

  #tour .more,
  #tour .select {
    font-size: 14px;
  }

  #tour .desc {
    font-size: 14px;
  }

  #partners .form-partner {
    padding: 100px 50px;
  }

  #support .support-box {
    padding: 26px;
    margin: 18px 18px;
    width: auto;
    height: 250px;
  }

  ul.support-form {
    padding: 0 80px 50px;
  }

  #news .news-inner {
    padding: 100px 60px;
  }

  #generate .step1-form .intro {
    padding: 0 50px;
  }

  #generate .payment-form {
    padding: 0;
  }

  .partner-logo {
    margin-top: -50px;
  }

  .dashboard-header {
    padding-bottom: 150px;
  }

 

  .navbar-right {
    margin-right: -12px;
  }

  .arrow-left {
    left: -363px;
    top: 80px;
  }

  .arrow-right {
    right: -363px;
    top: -65px;
  }
}

@media (max-width: 767px) {
  .partner-logo {
        margin: 0 auto;
    text-align: center;
  }
  
  #my-account .tour-buttons .btn{
    margin-bottom: 16px;
  }
  
  #home .video-container{
        height: 576px;
  }
  #tour .video-container{
    height: 610px;
  }

  #main-footer .footer-widgets{
    margin-top: 16px;
    padding: 0 36px;
  }

  #footer-bottom div.row div{
    text-align: center;
    float: none;
  }

  #et-social-icons{
    text-align: center;
    margin-top: 10px;
  }

  #generate .payment .services.col-md-6{
        padding: 0 40px;
  }
}

@media (max-width: 714px) {
  #home .steps-border {
    margin-top: -112px;
  }
}

@media (max-width: 700px) {
   #home .video-container{
        height: 544px;
  }
  #tour .video-container{
    height: 576px;
  }
}  

@media (max-width: 640px) {
  
  #home .video-container{
    height: 514px;
  }
  #tour .video-container{
    height: 544px;
  }
  .video-container video{
    margin-left: 8px;
  }
  
  #news .element-item {
    width: 100%;
  }

  #news .news-inner {
    padding: 100px 30px;
  }

  input[type=text],
  input[type=email],
  input[type=password],
  textarea,
  select {
    width: 100%;
    font-size: 18px;
  }

  #partners .partner-section .about-partnership {
    margin-bottom: 0 !important;
  }

  #support .support-box {
    height: 285px;
  }

  #support .support-form {
    padding: 0 100px 50px;
  }

  #generate ul.steps li div.divider {
    width: 100px;
  }

  #generate .state {
    margin-left: 0;
  }

  #generate .postcode {
    margin-right: 0;
  }


  #generate .step1-form .intro {
    padding: 0;
  }

  #generate .step1-form form,
  #generate .payment {
    padding: 50px 0;
  }

  .support-topic {
    display: block !important;
    width: 334px;
    margin: 0 auto;
  }

  #tour .service {
    margin: 5px;
    width: 116px;
    padding: 4px;
    height: 122px;
  }

  #tour .morebtn,
  #tour .lessbtn {
    height: 88px;
  }

  #tour h4 {
    font-size: 14px;
  }

  #tour .more,
  #tour .select {
    font-size: 12px;
  }

  #tour .desc {
    font-size: 14px;
  }

 

  #generate .payment-option {
    width: 40%;
  }

  #partners .support-box .free-guide a.btn {
    padding: 10px 0;
    width: 100%;
  }

  
  #generate .service-mobile{
    display: block;
    padding: 50px 0; text-align: center;
  }
  
  #generate .service-desktop{
    display: none;
  }
  
  #generate ul.favorites li.fav-text{
    display: none;
  }
  
  
  #generate .service-mobile p.favourites{
    font-size: 32px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px auto 22px;
  }
  
  
  #generate .service{
    width: 100%;
    margin: 16px auto;
    padding: 20px;
    height: auto;
  }
  
  
  #generate .service h4{
    font-size: 20px;
      text-align:left;
      padding: 0;
      margin-bottom: 8px;
  }
  
  #generate .service p{
      font-size: 18px;
      text-align:left;
      padding: 0;
  }
  
  #generate .service img{
    vertical-align: middle;
    margin-right: 20px;
  }
  
  
  #generate .services-btn{
    display:none;
  }
}

@media (max-width: 639px) {
  #home .video-container{
        height: 508px;
  }
  #tour .video-container{
    height: 544px;
  }
}  

@media (max-width: 480px) {

  #home .home-wrapper {
margin-top: 50px;
}
 
#home h1 {
font-size: 15vw;
padding: 0;
}
 
#home .home-wrapper .sub-title {
margin-bottom: 15px;
}

  #home .steps-border {
    margin-top: -104px;
  }

  .img-partner {
    display: none;
  }

  #generate ul.steps li div.divider {
    width: 50px;
  }

  #generate ul.steps li div.num {
    padding: 22px;
    height: 72px;
    width: 72px;
  }

  #generate .btn.btn-general {
    width: 44%;
    padding: 20px 0;
  }

  #home ul.steps li div.divider {
    width: 30px;
  }

  #home ul.steps.step-text p {
    font-size: 14px;
  }

  #home .about-you {
    margin-left: -60%;
  }

  #home ul.steps li div.divider2 {
    display: none;
  }

  #home .your-site {
    margin-left: -18%;
  }

  #home .finished {
    margin-left: 38%;
  }

  #home ul.steps li div.num {
    padding: 22px;
    height: 72px;
    width: 72px;
  }

  #home .homepage-url {
    width: 100% !important;
    font-size: 18px !important;
    margin-bottom: 12px;
  }

  .getstarted-btn {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 18px;
    padding: 14px;
  }

  #partners .support-box {
    padding: 32px 0;
  }


  #support .support-box {
    height: 280px;
    padding: 14px;
    margin: 20px 10px;
  }

  #support .support-section {
    padding: 0 50px 50px;
  }

  #support .support-form {
    padding: 0 50px 50px;
  }



  #generate .payment-option {
    width: 74%;
  }

  /* Apply CSS to iOS affected versions only */
  body.iosBugFixCaret.modal-open { position: fixed; width: 100%; }
  .modal-open { position: fixed; }

 

  .modal-body {
    padding: 0px 24px 40px;
  }

  #main-footer .footer-widgets{
    text-align: center;
  }

  #main-footer .footer-widgets div{
    width: 100%;
  }
#my-account .dashboard-wrapper .dashboard-info{
  padding-right: 0;
  } 

  #generate .step1-form .intro span{
    font-size:6vw;
  }

  .header-title .row{
    margin: 40px auto 30px;
  }

  .header-title .row h1{
    font-size: 3em;
  }

  #generate .terms p{
    font-size: 16px;
    margin-left: 10px;
  }

#generate .payment .services.col-md-6{
  padding: 0;
}

#generate .payment .services.col-md-6 span.list{
  margin-left: 2px;
}

#main-footer .footer-widgets p{
  width: 90%;
}
  

}
@media (max-width: 412px) {
  
   #home .video-container{
      height: 400px;
   }
   #tour .video-container{
    height: 424px;
  }
}  
@media (max-width: 414px) {
  #home .service {
    width: 152px;
    height: 160px;
  }

  .btn-general {
    padding: 10px 42px;
  }

  #support .support-section {
    padding: 0 0 50px;
  }

  #support .support-form {
    padding: 0 40px 50px;
  }

  #home .fav-text {
    font-size: 20px;
  }
 

  #news button.btn-filter {
    margin-right: 5px;
  }

  #home .steps-border {
    margin-top: -126px;
  }
  
   #home .video-container{
      height: 402px;
   }
   
   #tour .video-container{
    height: 432px;
  }
  
   .video-container video{
         margin-left: 6px;
    }  
  
}

@media (max-width: 400px) {

}

@media (max-width: 375px) {
    #home .video-container{
          height: 382px;
   }
   
   #tour .video-container{
       height: 414px;
  }
  .video-container video{
    
   } 
}

@media (max-width: 360px) {
  .navbar-header #logo {
    margin: 0;
  }

  #logo img {
    max-width: 230px;
  }
  
   #home .video-container{
      height: 402px;
   }
   #tour .video-container{
    height: 406px;
  }
}

@media (max-width: 320px) {
  #logo img {
    max-width: 200px;
    margin-left: 0px;
    margin-top: -4px;
  }
  
   #home .video-container{
     height: 376px;
   }
   
    .video-container video{
     margin-left: 4px;
   }

}

nav.navbar img.inverse-image-logo {
  display: none;
}

nav.navbar.shrink {
  background-color: #253846;
}

nav.navbar.shrink img.inverse-image-logo {
  display: inline-block;
}

nav.navbar.shrink img.image-logo {
  display: none;
}

nav.navbar.shrink #navbar li a {
  color: #FFF;
}

nav.navbar.shrink #navbar li a:hover,
nav.navbar.shrink #navbar li.active > a {
  color: #2b7de1;
}

nav.navbar.shrink .create-btn {
  background-color: #00BC9C;
  color: #fff !important;
}

nav.navbar.shrink .create-btn:hover {
  background-color: #00BC9C;
  color: #fff !important;
}


textarea.materialize-textarea {
  width: 100%;
  box-sizing: content-box;
  transition: all 0.3s;
}

input:not([type]):disabled + label,
input:not([type])[readonly="readonly"] + label,
input[type=text]:disabled + label,
input[type=text][readonly="readonly"] + label,
input[type=password]:disabled + label,
input[type=password][readonly="readonly"] + label,
input[type=email]:disabled + label,
input[type=email][readonly="readonly"] + label,
input[type=url]:disabled + label,
input[type=url][readonly="readonly"] + label,
input[type=time]:disabled + label,
input[type=time][readonly="readonly"] + label,
input[type=date]:disabled + label,
input[type=date][readonly="readonly"] + label,
input[type=datetime]:disabled + label,
input[type=datetime][readonly="readonly"] + label,
input[type=datetime-local]:disabled + label,
input[type=datetime-local][readonly="readonly"] + label,
input[type=tel]:disabled + label,
input[type=tel][readonly="readonly"] + label,
input[type=number]:disabled + label,
input[type=number][readonly="readonly"] + label,
input[type=search]:disabled + label,
input[type=search][readonly="readonly"] + label,
textarea.materialize-textarea:disabled + label,
textarea.materialize-textarea[readonly="readonly"] + label {
  color: rgba(0, 0, 0, 0.26);
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #2b7de1;
 
}

label.active {
  color: #253746 !important;
}

input:not([type]):focus:not([readonly]) span + label,
input[type=text]:focus:not([readonly]) span + label,
input[type=password]:focus:not([readonly]) span + label,
input[type=email]:focus:not([readonly]) span + label,
input[type=url]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #253746;
}

input:not([type]).valid,
input:not([type]):focus.valid,
input[type=text].valid,
input[type=text]:focus.valid,
input[type=password].valid,
input[type=password]:focus.valid,
input[type=email].valid,
input[type=email]:focus.valid,
input[type=url].valid,
input[type=url]:focus.valid,
input[type=time].valid,
input[type=time]:focus.valid,
input[type=date].valid,
input[type=date]:focus.valid,
input[type=datetime].valid,
input[type=datetime]:focus.valid,
input[type=datetime-local].valid,
input[type=datetime-local]:focus.valid,
input[type=tel].valid,
input[type=tel]:focus.valid,
input[type=number].valid,
input[type=number]:focus.valid,
input[type=search].valid,
input[type=search]:focus.valid,
textarea.materialize-textarea.valid,
textarea.materialize-textarea:focus.valid {
  border-bottom: 1px solid #44a247;
  box-shadow: 0 1px 0 0 #44a247;
}

input:not([type]).valid + label:after,
input:not([type]):focus.valid + label:after,
input[type=text].valid + label:after,
input[type=text]:focus.valid + label:after,
input[type=password].valid + label:after,
input[type=password]:focus.valid + label:after,
input[type=email].valid + label:after,
input[type=email]:focus.valid + label:after,
input[type=url].valid + label:after,
input[type=url]:focus.valid + label:after,
input[type=time].valid + label:after,
input[type=time]:focus.valid + label:after,
input[type=date].valid + label:after,
input[type=date]:focus.valid + label:after,
input[type=datetime].valid + label:after,
input[type=datetime]:focus.valid + label:after,
input[type=datetime-local].valid + label:after,
input[type=datetime-local]:focus.valid + label:after,
input[type=tel].valid + label:after,
input[type=tel]:focus.valid + label:after,
input[type=number].valid + label:after,
input[type=number]:focus.valid + label:after,
input[type=search].valid + label:after,
input[type=search]:focus.valid + label:after,
textarea.materialize-textarea.valid + label:after,
textarea.materialize-textarea:focus.valid + label:after {
  content: attr(data-success);
  color: #44a247;
  opacity: 1;
}

input:not([type]).invalid,
input:not([type]):focus.invalid,
input[type=text].invalid,
input[type=text]:focus.invalid,
input[type=password].invalid,
input[type=password]:focus.invalid,
input[type=email].invalid,
input[type=email]:focus.invalid,
input[type=url].invalid,
input[type=url]:focus.invalid,
input[type=time].invalid,
input[type=time]:focus.invalid,
input[type=date].invalid,
input[type=date]:focus.invalid,
input[type=datetime].invalid,
input[type=datetime]:focus.invalid,
input[type=datetime-local].invalid,
input[type=datetime-local]:focus.invalid,
input[type=tel].invalid,
input[type=tel]:focus.invalid,
input[type=number].invalid,
input[type=number]:focus.invalid,
input[type=search].invalid,
input[type=search]:focus.invalid,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea:focus.invalid {
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #F44336;
}

input:not([type]).invalid + label:after,
input:not([type]):focus.invalid + label:after,
input[type=text].invalid + label:after,
input[type=text]:focus.invalid + label:after,
input[type=password].invalid + label:after,
input[type=password]:focus.invalid + label:after,
input[type=email].invalid + label:after,
input[type=email]:focus.invalid + label:after,
input[type=url].invalid + label:after,
input[type=url]:focus.invalid + label:after,
input[type=time].invalid + label:after,
input[type=time]:focus.invalid + label:after,
input[type=date].invalid + label:after,
input[type=date]:focus.invalid + label:after,
input[type=datetime].invalid + label:after,
input[type=datetime]:focus.invalid + label:after,
input[type=datetime-local].invalid + label:after,
input[type=datetime-local]:focus.invalid + label:after,
input[type=tel].invalid + label:after,
input[type=tel]:focus.invalid + label:after,
input[type=number].invalid + label:after,
input[type=number]:focus.invalid + label:after,
input[type=search].invalid + label:after,
input[type=search]:focus.invalid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea:focus.invalid + label:after {
  content: attr(data-error);
  color: #F44336;
  opacity: 1;
}

input:not([type]).validate + label,
input[type=text].validate + label,
input[type=password].validate + label,
input[type=email].validate + label,
input[type=url].validate + label,
input[type=time].validate + label,
input[type=date].validate + label,
input[type=datetime].validate + label,
input[type=datetime-local].validate + label,
input[type=tel].validate + label,
input[type=number].validate + label,
input[type=search].validate + label,
textarea.materialize-textarea.validate + label {
  width: 100%;
  pointer-events: none;
}

input:not([type]) + label:after,
input[type=text] + label:after,
input[type=password] + label:after,
input[type=email] + label:after,
input[type=url] + label:after,
input[type=time] + label:after,
input[type=date] + label:after,
input[type=datetime] + label:after,
input[type=datetime-local] + label:after,
input[type=tel] + label:after,
input[type=number] + label:after,
input[type=search] + label:after,
textarea.materialize-textarea + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 60px;
  opacity: 0;
  transition: .2s opacity ease-out, .2s color ease-out;
}

.input-field {
  position: relative;
  margin-top: 1rem;
}

.input-field.inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.input-field.inline input,
.input-field.inline .select-dropdown {
  margin-bottom: 1rem;
}

.input-field.col label {
  left: 1rem;
}

.input-field.col .prefix ~ label,
.input-field.col .prefix ~ .validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}

.input-field label {
  color: #a3b1bf;
  position: absolute;
  top: 2rem;
  left: 0.2em;
  font-size: 22px;
  cursor: text;
  transition: .2s ease-out;
  text-align: initial;
  font-weight: 600;
}

.input-field label:not(.label-icon).active {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
  transform: translateY(-140%);
}

.input-field .prefix {
  position: absolute;
  width: 3rem;
  font-size: 2rem;
  transition: color .2s;
}

.input-field .prefix.active {
  color: #253746;
}

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.input-field .prefix ~ label {
  margin-left: 3rem;
}

.has-feedback label ~ .form-control-feedback {
  top: 10px;
}

@media only screen and (max-width: 992px) {
  .input-field .prefix ~ input {
    width: 86%;
    width: calc(100% - 3rem);
  }
}

@media only screen and (max-width: 600px) {
  .input-field .prefix ~ input {
    width: 80%;
    width: calc(100% - 3rem);
  }
}

/* Search Field */

.input-field input[type=search] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);
}

.input-field input[type=search]:focus {
  background-color: #fff;
  border: 0;
  box-shadow: none;
  color: #444;
}

.input-field input[type=search]:focus + label i,
.input-field input[type=search]:focus ~ .mdi-navigation-close,
.input-field input[type=search]:focus ~ .material-icons {
  color: #444;
}

.input-field input[type=search] + label {
  left: 1rem;
}

.input-field input[type=search] ~ .mdi-navigation-close,
.input-field input[type=search] ~ .material-icons {
  position: absolute;
  top: 0;
  right: 1rem;
  color: transparent;
  cursor: pointer;
  font-size: 2rem;
  transition: .3s color;
}

/* Textarea */

textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
}

textarea.materialize-textarea {
  overflow-y: hidden;
  resize: none;
  min-height: 3rem;
}

.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem;
  /* prevents text jump on Enter keypress */
  position: absolute;
  top: 0;
}

/* Autocomplete */

.autocomplete-content {
  margin-top: -20px;
  display: block;
  opacity: 1;
  position: static;
}

.autocomplete-content li .highlight {
  color: #444;
}

.autocomplete-content li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
}

.close_success{
  cursor: pointer;
}

.p0 {
  padding: 0;
}

.p10{
 padding: 0 10px; 
}

input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

input[type="checkbox"] + label {
    height: 25px;
    width: 26px;
    border: 2px solid #a3b1bf;
    
}
input[type="checkbox"]:checked + label {
    
    background: url("/images/tick-white.png") 0 0 no-repeat;
    background-color: #2b7de1;
    border: 2px solid #2b7de1;
}

.modal input[type="checkbox"] + label {
    height: 25px;
    width: 26px;
    border: 2px solid #FFF;
    padding: 2px 12px;
    position: initial;
    float: left;
    
    margin-right: 10px;
}

.modal input[type="checkbox"]:checked + label{
    background: url("/images/blue-tick.png") 0 0 no-repeat;
    background-color: #FFF;
    border: 2px solid #2b7de1;
}

.remember-me{
    text-align: left;
    vertical-align: top;
    font-size: 16px;
}

.terms p{
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;
}

.not-found {
    text-align: center;
    background-image: url("/images/MyPrivacyPolicy-C2A-Background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 876px;
}

.not-found h1{
  color: #FFF;
  font-size: 92px;
}

.not-found-content{
    top: 32%;
    left: 44%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.not-found p{
  font-size: 28px;
  font-weight: 600;
      margin-bottom: 50px;
      color: #FFF;
}

.not-found a{
  color: #FFF;
  padding: 10px 90px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-weight: 700;
  border: 2px solid #FFF;
  font-size: 18px;
  text-transform: uppercase;
  background: transparent;  
}

#generate .popover{
  border: none;
}
#generate .popover-title{
      margin: 0 !important;
    padding: 16px;
    font-size: 16px;
    background-color: #2B7DE1;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
    color: #FFF;
}

#generate .popover-content{
  padding: 0;
}

#generate form.form-inline.editableform{
      padding: 25px 100px !important;
}

.select-sm{
  padding: 5px 10px;
      height: 30px;
}

#generate .payment p a.editable{
    border: none;
    font-size: 20px;
    color: #73879C;
    line-height: 1.5em;
    font-weight: 600;
}

.g-recaptcha{
    margin: 0 auto;
    width: 34%;
    display: block;
}
div.g-recaptcha > div{
   width: 100% !important;
}

.captcha-error{
  text-align: center;
}

.news-recaptcha{
  text-align: center;
  width: 100%;
}

#modalBtn{
  display:none;
}

.article-background{
	background-color: rgba(0,0,0,0.65);
	background-blend-mode: multiply;
}
